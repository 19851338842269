import "./fonts.css";

import styled from "styled-components";

import { NotFound } from "./Utils";

import Info from "./Info";
import Estates from "./Estates";
import Start from "./Start";
import Navigation from "./Navigation";

import { Route, Routes } from "react-router-dom";
import DataPolicy from "./DataPolicy";

export const Container = styled.div`
  font-family: "Open Sans", sans-serif;
  text-align: center;
`;

function App() {
  return (
    <Container>
      <Navigation />
      <Routes>
        <Route
          path="/personuppgiftspolicy"
          element={
            <>
              <DataPolicy />
            </>
          }
        />
        <Route
          path="/"
          element={
            <>
              <Start />
              <Estates />
            </>
          }
        />
        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
      <Info />
    </Container>
  );
}

export default App;
