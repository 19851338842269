import { company_name, city, company_org_number } from "./Info";
import { Document, Item, Mail } from "./Utils";

function DataPolicy() {
  return (
    <>
      <Item>
        <article>
          <Document>
            <h1>PERSONUPPGIFTSPOLICY</h1>

            <h2>INFORMATION OM BEHANDLING AV PERSONUPPGIFTER</h2>
            <p>
              Vi behandlar personuppgifter när du söker bostad, när du sedan
              blir kund hos oss och även en tid efter att din tid som kund hos
              oss har upphört. Din integritet är viktig för oss och vi vill
              därför att du läser vidare för att få veta mer. En personuppgift
              är all slags information som kan kopplas till dig som person, till
              exempel namn, adress, kontaktuppgifter som telefonnummer och
              e-postadresser.
            </p>

            <h2>PERSONUPPGIFTSANSVARIG</h2>
            <p>
              {company_name}, {city}, {company_org_number}, (
              <Mail to="data@bigfastigheter.se" />) är personuppgiftsansvarig
              för behandling av dina personuppgifter som vi gör själva eller
              annat företag gör på vårt uppdrag.
            </p>

            <h2>NÄR DU GÖR EN BOSTADSANSÖKAN</h2>
            <p>
              När du gör en bostadsansökan samlar vi in och behandlar dina
              personuppgifter för att administrera din ansökan/intresseanmälan.
              Det gör vi för att vi har ett berättigat intresse av att behandla
              dina personuppgifter. Uppgifterna ligger kvar så länge din ansökan
              är aktuell. Om du inte önskar stå kvar med din ansökan kan du
              begära att vi rensar bort och raderar dina personuppgifter.
            </p>

            <h2>NÄR DU ERBJUDS EN BOSTAD HOS OSS</h2>
            <p>
              När du blir erbjuden en bostad hos oss behöver vi behandla fler
              uppgifter om dig. Då behöver vi till exempel uppgifter om din
              ekonomi, om din anställning och hur du skött tidigare boenden och
              hyresinbetalningar. Det innebär att vi gör en kreditupplysning,
              hämtar uppgifter från inkassobolag och Kronofogdemyndigheten och
              behandlar anställningsintyg, andra intyg och uppgifter från
              referenspersoner du lämnar till oss. Om du har en förvaltare eller
              god man behöver vi behandla de uppgifterna. Vi sparar dina
              uppgifter så länge de är relevanta och aktuella. Kreditupplysning,
              uppgifter från inkassobolag och myndigheter som vi samlat in för
              att godkänna dig som hyresgäst sparar vi inte. Om du blir nekad en
              lägenhet hos oss på grund av att vi inte kan godta dig som
              hyresgäst kommer vi dock att bevara dina personuppgifter i tre
              månader efter avslagsbeslutet.
            </p>

            <h2>UNDER HYRESFÖRHÅLLANDET</h2>
            <p>
              När du blir kund hos oss och får ett hyresavtal behandlar vi dina
              personuppgifter för att kunna fullgöra och administrera våra
              skyldigheter som hyresvärd och avtalspart. Under hyresförhållandet
              behandlar vi till exempel dina personuppgifter när vi skickar ut
              hyresavier och hanterar dina betalningar, när vi inhämtar
              uppgifter om förbrukning av el och/eller vatten/värme, när vi
              hyresförhandlar och när vi skickar information till dig som du
              behöver. Vi kan också behöva uppdatera dina personuppgifter mot
              offentliga register för att säkerställa att de är korrekta. Om du
              har skyddade personuppgifter kommer vi att hantera dina uppgifter
              enligt våra rutiner för sådana. Vi behandlar också dina
              personuppgifter för att kunna tillhandahålla våra tjänster och
              service till dig samt för att marknadsföra oss och våra tjänster.
              Denna behandling är nödvändig för vårt berättigade intresse att
              utveckla, förbättra och sälja produkter och tjänster och behålla
              en god kundkontakt med dig. Dina personuppgifter kommer också att
              behandlas så att andra företag kan marknadsföra sina produkter som
              du kan ha nytta av i samband med hyresförhållandet. Sådan
              marknadsföring kan avse bredbandsbolag, försäkringsbolag och
              elbolag. Vi behandlar också dina personuppgifter när det är
              nödvändigt för att tillvarata ett rättsligt anspråk. Det kan vi
              behöva göra om det till exempel förekommer störningar i boendet,
              sena eller uteblivna hyresbetalningar eller skador i lägenheten.
              Vi kan också behöva lämna ut dina personuppgifter till
              socialnämnden eller andra berörda myndigheter. Vi kan komma att
              anlita personuppgiftsbiträde för behandling av dina
              personuppgifter. Det kan till exempel gälla en entreprenör som ska
              reparera något i din lägenhet ett företag som tillhandahåller
              bredband eller el, ett inkassoföretag eller ett företag som sköter
              våra IT-system. Vi lämnar också ut dina personuppgifter när det
              följer av lag eller myndighetsbeslut. Om uppgifterna överförs till
              ett land utanför EU, säkerställer vi att sådan överföring är
              laglig. Vi sparar dina personuppgifter under den tid de är
              relevanta. Ett borgensåtagande, beslut om förvaltare eller god man
              raderar vi två år efter åtagandets eller beslutet har upphört att
              gälla.
            </p>

            <h2>NÄR HYRESFÖRHÅLLANDET ÄR SLUT</h2>
            <p>
              Vi kommer att gallra och rensa personuppgifter om dig när du
              flyttat från din lägenhet men vissa uppgifter måste vi spara i
              minst två år därefter som till exempel hyresavtalet, beslut från
              socialnämnd och studieintyg. Uppgifter om dig som finns i vårt
              bokföringsmaterial, till exempel betalningar, kommer vi att spara
              i sju år inklusive innevarande år.
            </p>

            <h2>DINA RÄTTIGHETER</h2>
            <p>
              Om du inte vill att dina personuppgifter ska behandlas för
              marknadsföringsändamål kan du när som helst meddela oss detta. Du
              har rätt att få information om vilka personuppgifter vi hanterar
              om dig, ett registerutdrag. Du har också rätt att få felaktiga
              uppgifter rättade. Du har också rätt att kräva att vi begränsar
              vår behandling av dina personuppgifter om du till exempel anser
              att de inte är korrekta. Om du inte tycker att vi behandlar dina
              personuppgifter i enlighet med dataskyddsförordningen har du rätt
              att klaga på detta till oss och till tillsynsmyndigheten,
              Dataskyddsinspektionen. Du har rätt att få dina personuppgifter
              överförda till ett annat företag (dataportabilitet).
            </p>
          </Document>
        </article>
      </Item>
    </>
  );
}

export default DataPolicy;
