import styled from "styled-components";

import { isDefined } from "@nextml/lodestar";

import { number_format, Item, static_src } from "./Utils";

import Application from "./Application";

const EstateContainer = styled.div``;

const Presentation = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

const Image = styled.div`
  flex: 1;
  min-width: 400px;
`;

const Map = styled.div`
  flex: 1;
  min-width: 400px;
`;

const SubPresentation = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

const Vacants = styled.div`
  flex: 1;
  width: 100%;
  padding: 10px 20px 10px 20px;
`;
const VacantsMessage = styled.div``;
const VacantsTable = styled.ul`
  list-style: none;
`;
const Vacant = styled.li``;
const VacantCell = styled.div`
  margin: auto;
  padding: 0px 10px 0px 10px;
  display: inline-block;
`;

const ApplicationContainer = styled.div`
  width: 100%;
  padding: 40px 70px 40px 70px;
`;

function Estate(props) {
  return (
    <Item>
      <EstateContainer>
        <article>
          <h3>{`${props.adress_range}, ${props.city}`}</h3>
          <Presentation>
            <Image>
              <img
                width="100%"
                style={{ display: "block" }}
                src={static_src(props.image)}
                alt={`Bild på fastigheten ${props.property_designation} i ${props.adress_range}, ${props.city}`}
              />
            </Image>
            <Map>
              <iframe
                title={props.title}
                src={props.src}
                width="100%"
                height="100%"
                style={{ border: "0", display: "block" }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Map>
          </Presentation>
          <SubPresentation>
            <Vacants>
              <h3>Ledigt</h3>
              <VacantsTable>
                {props.vacants ? (
                  props.vacants.map((vacant, index) => (
                    <Vacant key={index}>
                      <VacantCell>
                        {!isDefined(vacant.type) ? (
                          vacant.rokv ? (
                            <>{number_format(vacant.rokv)} rokv</>
                          ) : (
                            <>{number_format(vacant.rok)} rok</>
                          )
                        ) : (
                          <>{vacant.type}</>
                        )}
                      </VacantCell>
                      <VacantCell>{number_format(vacant.yta)} kvm</VacantCell>
                      <VacantCell>{vacant.comment}</VacantCell>
                      <VacantCell>
                        {isDefined(vacant.inflytt) ? (
                          <>inflytt {vacant.inflytt}</>
                        ) : (
                          <></>
                        )}
                      </VacantCell>
                      <VacantCell>
                        {"f n "}
                        {isDefined(vacant.hyra)
                          ? number_format(vacant.hyra)
                          : "-"}
                        {" SEK/månad"}
                      </VacantCell>
                    </Vacant>
                  ))
                ) : (
                  <></>
                )}
              </VacantsTable>
              <VacantsMessage>
                {props.vacants && props.vacants.length > 0 ? (
                  <>
                    Sök någon av de lediga lägenheterna eller ställ dig i kö för
                    kommande genom att ansöka via länken nedan
                  </>
                ) : (
                  <>
                    Inga lediga lägenheter just nu, men du kan ställa dig i kö
                    genom att ansöka via länken nedan
                  </>
                )}
              </VacantsMessage>
            </Vacants>
            <ApplicationContainer>
              <Application />
            </ApplicationContainer>
          </SubPresentation>
        </article>
      </EstateContainer>
    </Item>
  );
}

function Estates() {
  return (
    <>
      <Estate
        property_designation="Vargen 1"
        city="Lidingö"
        adress="Källängsvägen 44"
        adress_range="Källängsvägen 38-54"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2032.499057148606!2d18.137601516338393!3d59.37469911442577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f832d9bc58b87%3A0x6fa78dc8b5b21898!2zS8OkbGzDpG5nc3bDpGdlbiA0NCwgMTgxIDQ0IExpZGluZ8O2!5e0!3m2!1sen!2sse!4v1644348462257!5m2!1sen!2sse"
        image="vargen-1.jpg"
        vacants={[]}
      />
      <Estate
        property_designation="Norr 26:4"
        city="Gävle"
        adress="Norra Centralgatan 7"
        adress_range="Norra Centralgatan 7"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d488.4937943240387!2d17.14804832929394!3d60.67648045567752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4660c6668fd5a43b%3A0x8cde1c4562ad7224!2sNorra%20Centralgatan%207%2C%20803%2011%20G%C3%A4vle!5e0!3m2!1sen!2sse!4v1644348965676!5m2!1sen!2sse"
        image="norr-1.jpg"
        vacants={[
        ]}
      />
      <Estate
        property_designation="Söder 35:1"
        city="Gävle"
        adress="Brunnsgatan 54"
        adress_range="Brunnsgatan 54 & Södra Stapeltorgsgatan 13"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1954.4263115565204!2d17.14467664420899!3d60.66904957564346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4660c65dca1f767d%3A0xc322e7973a6f7450!2sBrunnsgatan%2054%2C%20802%2052%20G%C3%A4vle!5e0!3m2!1sen!2sse!4v1644349191757!5m2!1sen!2sse"
        image="soder-2.jpg"
        vacants={[]}
      />
      <Estate
        property_designation="Väster 24:18"
        city="Gävle"
        adress="Staketgatan 8"
        adress_range="Staketgatan 4-8 & Skomakargatan 7a-c"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1954.0877280929506!2d17.13348524587594!3d60.67462662041022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4660c669b96cba7d%3A0x24ac514aabf86609!2sStaketgatan%206%2C%20803%2024%20G%C3%A4vle!5e0!3m2!1sen!2sse!4v1644349539773!5m2!1sen!2sse"
        image="vaster-1.jpg"
        vacants={[
        ]}
      />
    </>
  );
}

export default Estates;
