import styled from "styled-components";

const Apply = styled.button`
  padding: 8px 16px 8px 16px;
  border-style: none;
  border-radius: 4px;
  font-size: xx-large;
  color: white;
  background-color: #f15035;
  cursor: pointer;
`;

function Application() {
  return (
    <div>
      <a
        href="https://forms.gle/CxFcrmiiNqCsR7Bt5"
        target="_blank"
        rel="noreferrer"
      >
        <Apply>Ansök här</Apply>
      </a>
    </div>
  );
}

export default Application;
