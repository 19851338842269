import styled from "styled-components";

import { useLocation, Link } from "react-router-dom";

export const mobile_width = 768;

export const number_format = new Intl.NumberFormat("se-SE").format;

export function static_src(name) {
  return `https://s3.eu-central-1.wasabisys.com/big-fastigheter/website/static/${name}`;
}

// NOTE For documents of text like legal-text, information etc.
export const Document = styled.div`
  text-align: left;
  max-width: 60em;

  // center
  margin-left: auto;
  margin-right: auto;
`;

// NOTE One section in the linear flow of the website.
export const Item = styled.div`
  @media only screen and (max-width: ${mobile_width}px) {
    padding: 20px 35px 20px 35px;
  }
  padding: 40px 70px 40px 70px;

  border-bottom: 1px solid #e0e0e0;
`;

export function Mail(props) {
  return (
    <>
      <a href={`mailto:${props.to}`}>{props.to}</a>
    </>
  );
}

export function NotFound() {
  const location = useLocation();
  return (
    <>
      <Item>
        <Document>
          <h2>404 - Sidan kan inte hittas ({location.pathname})</h2>

          <p>Sidan du letar efter kan tyvärr inte hittas.</p>

          <p>
            Det kan bero på flera saker:
            <ul>
              <li>Länken från föregående sida är bruten</li>
              <li>
                Webbadressen har blivit fel (kontrollera att den inte innehåller
                några stavfel om du skrivit in den själv)
              </li>
              <li>
                Sidan har tagits bort eller flyttats, och hittas därför inte av
                sökmotorn
              </li>
            </ul>
          </p>

          <p>
            Gå tillbaka till <Link to="/">start</Link>.
          </p>
        </Document>
      </Item>
    </>
  );
}
