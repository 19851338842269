import { Item, Document } from "./Utils";

function Start() {
  return (
    <>
      <Item>
        <article>
          <Document>
            <h1>Välkommen till BiG Fastigheter</h1>
            <p>
              BiG Fastigheter förvaltar hyresbostäder och kommersiella lokaler
              på Lidingö och i Gävle. Vi har förvaltat fastigheter sedan 1976
              och är alltid långsiktig i vårt ägande. Vår ambition är att
              förmedla trygga boenden med en känsla av gemenskap.
            </p>
          </Document>
        </article>
      </Item>
    </>
  );
}

export default Start;
