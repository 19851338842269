import styled from "styled-components";

const Header = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;

function Navigation() {
  return (
    <>
      <header>
        <Header>
          <h1>BiG Fastigheter</h1>
        </Header>
      </header>
    </>
  );
}

export default Navigation;
