import styled from "styled-components";

import { Link } from "react-router-dom";

import { Item } from "./Utils";

export const company_org_number = "556785-0234";
export const company_name = "Bjornen i Gävle AB";
export const city = "Gävle";

const Cursive = styled.p`
  text-align: center;
  font-size: 12px;
`;

function Info() {
  return (
    <Item>
      <article>
        {company_name}, Org.nr: {company_org_number}, Styrelens säte: {city}
        <Cursive>
          Alla rättigheter reserverade {company_name} ©{" "}
          {new Date().getFullYear()}
        </Cursive>
        <Cursive>
          <Link to="/">Start</Link>|
          <Link to="/personuppgiftspolicy">Personuppgiftspolicy</Link>
        </Cursive>
      </article>
    </Item>
  );
}

export default Info;
